const routes = [
    {
        name: 'a',
        path: '/testA/:billid',
        component: () => import('@/view/testA')
    },
    {
        name: 'b',
        path: '/testB',
        component: () => import('@/view/testB')
    },
    {
        name: 'c',
        path: '/purchaseBillCheck/:baseformcode/:billid/:checkercode/:timemark',
        component: () => import('@/view/purchaseBillCheck'),
        meta:{
            title:'采购单审核',
            keepAlive:false
        }
    },  
];

export default routes

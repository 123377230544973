/*
import {createRouter, createWebHashHistory} from 'vue-router'
import routes from './routes'

const router = createRouter({
    //history: createWebHistory(), 
    history: createWebHashHistory(),
    routes
})

export default router
*/

import { createRouter, createWebHistory, createWebHashHistory  } from 'vue-router';
import routes from "./routes";

const router = createRouter({
	//history: createWebHistory(process.env.BASE_URL),//默认时
	history: createWebHashHistory(process.env.BASE_URL),//修改后
	routes
});

router.beforeEach ((to, from, next) => {
    //路由后执行
    //if (to.meta.title) {
      document.title = to.meta.title;//显示标题
      next();//这个必须有,不然页面会空白
    //}
});

router.afterEach ((to, from, next) => {
    //路由后执行
    //if (to.meta.title) {
     // document.title = to.meta.title
    //}
  });
  
export default router;


import {createApp} from 'vue'
import App from './App.vue'
import router from './router/index'
import axios from '@/plugins/axiosInstance.js'

// 导入element-plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
 
//axios.defaults.baseURL = 'http://localhost:55342/api'
axios.defaults.baseURL = 'https://yferpapi.yuzin.cn/api';

const app = createApp(App);   //建立一个vue3app
app.use(ElementPlus);
app.use(router);              //注意use要在mount之前
app.mount('#app');            //将这个vue3app全局挂载到#app元素上
app.config.globalProperties.$axios=axios;  //配置axios的全局引用